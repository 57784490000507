import PropTypes from 'prop-types';
import React from 'react';
import Styles from './group-sound-system-spotlight.module.css';

const GroupSoundSystemSpotlight = props => {
  return <ul className={Styles.groupSoundSystemSpotlight}>{props.children}</ul>;
};

GroupSoundSystemSpotlight.defaultProps = {};

GroupSoundSystemSpotlight.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GroupSoundSystemSpotlight;
