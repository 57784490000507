import React, { useContext, useEffect, useState } from 'react';
import ThemeContext, { THEMES } from 'common/context.theme';
import { graphql, useStaticQuery } from 'gatsby';

import AppContext from '../common/context.app';
import GroupSoundSystemSpotlight from 'components/group-sound-system-spotlight';
import { HEADLINE_TYPES } from 'components/headline';
import PageHero from 'components/page-hero';
import SEO from '../components/seo';
import SoundSystemSpotlight from 'components/sound-system-spotlight';
import SoundSystemsShape from 'images/shapes/soundsystems.svg';
import carnivalSounds1 from '../../static/images/soundsystem-breaks/1.gif';
import carnivalSounds2 from '../../static/images/soundsystem-breaks/2.gif';
import carnivalSounds3 from '../../static/images/soundsystem-breaks/3.gif';

function shuffle(array = []) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const animations = [
  {
    name: 'Carnival Sounds',
    image: carnivalSounds1,
  },
  {
    name: 'Carnival Sounds',
    image: carnivalSounds2,
  },
  {
    name: 'Carnival Sounds',
    image: carnivalSounds3,
  },
];

const SoundSystemsPage = () => {
  const { setCurrentPage } = useContext(AppContext);
  const { setTheme } = useContext(ThemeContext);

  const [content, setContent] = useState(null);

  const data = useStaticQuery(graphql`
    query {
      allSoundsystemsJson {
        edges {
          node {
            video_url
            spotify_id
            name
            id
            cover_hover
            cover
            description
          }
        }
      }
    }
  `);

  useEffect(() => {
    setTheme(THEMES.dark);
    setCurrentPage('/sound-systems/');
  }, [setTheme, setCurrentPage]);

  useEffect(() => {
    const toReturn = [];
    const soundsystems = shuffle(data.allSoundsystemsJson.edges);
    let index = 0;

    soundsystems.forEach((soundsystem, soundsystemIndex) => {
      if ((soundsystemIndex + 1) % 4 === 0) {
        const indexAnimation = index % 3;
        index += 1;
        const anim = animations[indexAnimation];

        toReturn.push(
          <SoundSystemSpotlight
            key={`${soundsystemIndex}-${index}`}
            name={anim.name}
            image={anim.image}
            animation={true}
          />
        );
      }

      toReturn.push(
        <SoundSystemSpotlight
          key={soundsystemIndex}
          name={soundsystem.node.name}
          image={soundsystem.node.cover}
          hover={soundsystem.node.cover_hover}
          to={soundsystem.node.id}
        />
      );
    });

    setContent(toReturn);
  }, [setContent, data.allSoundsystemsJson.edges]);

  return (
    <>
      <SEO title="Sound Systems" />
      <div className="page">
        <PageHero
          className="takeovers-hero"
          headline={HEADLINE_TYPES.SOUNDSYSTEMS}
          shape={SoundSystemsShape}
        >
          Explore some of Notting Hill Carnival’s Sound Systems and Road DJs
        </PageHero>
      </div>
      {content && (
        <GroupSoundSystemSpotlight>{content}</GroupSoundSystemSpotlight>
      )}
    </>
  );
};

export default SoundSystemsPage;
