import React, { useContext } from 'react';

import AppContext from '../../common/context.app';
import AspectRatioBox from 'components/aspect-ratio-box';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import Styles from './sound-system-spotlight.module.css';
import { sendTrackingEvent } from 'common/sendTrackingEvent';

const SoundSystemSpotlight = props => {
  const { reducedMotion } = useContext(AppContext);
  let content;

  if (props.animation) {
    content = (
      <div className={Styles.picture} aria-hidden={reducedMotion}>
        <img src={props.image} loading="lazy" alt={props.name} />
      </div>
    );
  } else {
    const jpg = props.image;
    const gif = props.hover;
    content = (
      <Link
        to={props.to}
        className={Styles.link}
        onClick={() => sendTrackingEvent('soundsystems', 'click', props.name)}
      >
        <AspectRatioBox aspectRatio={1}>
          <div className={Styles.picture}>
            <picture>
              <img src={jpg} alt={props.name} />
            </picture>
          </div>

          <div className={`${Styles.picture} ${Styles.hover}`}>
            <picture>
              <img src={gif} loading="lazy" alt={props.name} />
            </picture>
          </div>
        </AspectRatioBox>
      </Link>
    );
  }

  let className = Styles.soundSystemSpotlight;
  className = props.animation ? `${className} ${Styles.animation}` : className;

  return (
    <li className={className} style={props.style}>
      <span className={Styles.name}>{props.name}</span>
      <div>{content}</div>
    </li>
  );
};

SoundSystemSpotlight.defaultProps = {};

SoundSystemSpotlight.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default SoundSystemSpotlight;
